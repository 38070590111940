<template>
    <div>

        <body id="body-pd" :class="' v-background ' + showMenuInit">
            <header :class="onGetClass" class="header body-pd pb-3" id="header">
                <div class="header_toggle " :class="openMenu ? 'show' : ''">
                    <i class='fas fa-bars' id="header-toggle" data-automation="button-menu" @click="showOrCloseMenu()"></i>
                </div>

                <div class="dropdown text-end">
                    <a href="#" class="d-block link-dark text-decoration-none dropdown-toggle" id="dropdownUser1"
                        data-bs-toggle="dropdown" aria-expanded="false">
                        <img :src="userProfileImage" alt="perfil" width="32" height="32" class="rounded-circle"
                        :onerror="onErrorImage"
                        >
                    </a>
                    <ul class="dropdown-menu text-small" aria-labelledby="dropdownUser1" style="">
                        <li>
                            <router-link
                                class="dropdown-item"
                                to="/users/my_perfil"
                            >
                            Perfil
                            </router-link>
                        </li>
                    </ul>
                </div>
            </header>

            <VFMenu id="menu" :class="onGetClass" />

            <div class="height-auto mb-5 v-background mt-2">
                <router-view></router-view>
            </div>

            <footer class="footer text-end me-3" :class="onGetClass" style="z-index: -1;">
                <span class="text-dark text-end"> {{ COMPANY_EMAIL }} - {{ COMPANY_PHONE }}</span>
            </footer>
        </body>
    </div>
</template>


<script>
import { computed, onMounted, onBeforeUnmount, inject, ref, onUpdated } from 'vue';
import { useRouter } from 'vue-router';
import { VFSelect } from '@/components';
import { VFMenu } from '@/components';
import { useStore } from "vuex";

import cfg from "@/service/Configurations";

export default {
    name: 'App',
    components: {
        VFMenu,
        VFSelect
    },
    setup() {
        const router = useRouter();
        const store = useStore();
        const eventBus = inject('eventBus');
        
        const openMenu = ref(true)
        const logoMenu = ref(true)
        const mobileMode = ref(false);
        const userProfileImage = ref('https://www.shutterstock.com/image-vector/vector-flat-illustration-grayscale-avatar-600nw-2281862025.jpg');
        
        const COMPANY_EMAIL = ref('')
        const COMPANY_PHONE = ref('')
        
        const showMenuInit = computed(() => {
            return (router.currentRoute.value.name !== 'Login') ? 'body-pd' : '';
        })

        const onGetClass = computed(() => {
            return (router.currentRoute.value.name === 'Login' || router.currentRoute.value.name === 'Registrarse') ? 'd-none' : '';
        })

        const updateProfileImage = (newProfileImage) => {
            if(newProfileImage !== null) {
                userProfileImage.value = newProfileImage;
            }
        }

        const onErrorImage = () => {
            userProfileImage.value = 'https://www.shutterstock.com/image-vector/vector-flat-illustration-grayscale-avatar-600nw-2281862025.jpg';
        }

        onMounted(() => {
            eventBus.on('updateProfileImage', updateProfileImage);
            if (window.innerWidth < 768) {
                document.getElementById("image_logo").classList.add('d-none')
                document.getElementById("image_logo_reduced").classList.remove('d-none')
                logoMenu.value = false
            }
            
            document.getElementById("body-pd").classList.remove('body-pd')

            const isMobileOrTablet = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
            if (isMobileOrTablet) {
                mobileMode.value = true;
                openMenu.value = false;
            }
            showOrCloseMenu()

            COMPANY_EMAIL.value = cfg.getConfiguration("COMPANY_EMAIL")
            COMPANY_PHONE.value = cfg.getConfiguration("COMPANY_PHONE")
        })

        onUpdated(() => {
            if(mobileMode.value){
                document.getElementById("body-pd").classList.remove('body-pd')
            }
        })

        onBeforeUnmount(() => {
            eventBus.off('updateProfileImage', updateProfileImage);
        })

        const showOrCloseMenu = () => {
            if(openMenu.value) {
                document.getElementById("body-pd").classList.add('body-pd')
                document.getElementById("header").classList.add('body-pd')
                document.getElementById("menu").classList.add('show-menu')
                if(mobileMode.value) {
                    document.getElementById("menu").classList.remove('d-none')
                }
                if(logoMenu.value) {
                    document.getElementById("image_logo").classList.remove('d-none')
                    document.getElementById("image_logo_reduced").classList.add('d-none')
                }
            } else {
                document.getElementById("body-pd").classList.remove('body-pd')
                document.getElementById("header").classList.remove('body-pd')
                if(mobileMode.value) {
                    document.getElementById("menu").classList.add('d-none')
                }
                if(logoMenu.value) {
                    document.getElementById("image_logo").classList.add('d-none')
                    document.getElementById("image_logo_reduced").classList.remove('d-none')
                }
                document.getElementById("menu").classList.remove('show-menu')
            }

            openMenu.value = !openMenu.value
        }
        
        return {
            onGetClass,
            showMenuInit,
            updateProfileImage,
            userProfileImage,
            onErrorImage,
            showOrCloseMenu,
            openMenu,
            COMPANY_EMAIL,
            COMPANY_PHONE
        }
    }
}
</script>