export default () => ({
    tableColumns: [
        {
            label: 'Nombre',
            field: 'name',
            sortable: false
        },
        {
            label: 'GTIN',
            field: 'gtin',
            sortable: false
        },
        {
            label: 'Tipo Medicamento',
            field: 'medicine_type',
            sortable: false
        },
        {
            label: 'Laboratorio',
            field: 'laboratory',
            sortable: false
        },
        {
            label: 'Acciones',
            field: 'buttons',
            sortable: false
        }
    ],
    isLoading: false,
    products : [],
    product : []
})