import { routingRuleApi } from "@/api";

/**
 *  Obtener los movimientos
 */
export const getMovements = async ({ commit , rootState },params) => {
    let code = 200;
    let types = {
        'receta_vale': 'Receta/Vale',
        'recibo_oficial': 'Recibo oficial',
        'factura': 'Factura',
        'vale_oficial': 'Vale oficial'
    };
    try {
        commit('setLoading', true);
        const queryString = Object.entries(params)
                        .filter(([key, value]) => value !== '')
                        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
                        .join('&');
        
        const { data } = await routingRuleApi.get(`/api/v1/movements?${queryString}`,rootState.auth.config);
        
        const movements = data.data.map( movements => {
            const { id, type, date, quantity, current_stock, origin, receipt, recibe_type, num_recibe_type,active,header } = movements;
            let product = `${movements.product.comercial_name} ${movements.product.presentation_name }`
            let typeParse = type.replaceAll("-"," ");
            let doctor = movements.doctor ? `${movements.doctor.name} ${movements.doctor.last_name} - N° Mat :${movements.doctor.number_registration}` : ''
            typeParse = typeParse.charAt(0).toUpperCase() + typeParse.slice(1);
            let typeReceipt = (header && header.type_receipt && header.type_receipt !== '') ? types[header.type_receipt] : ''

            return {
                id, 
                'product': product,
                "type": typeParse,
                date,
                quantity,
                current_stock,
                origin,
                "recibe_type" : typeReceipt,
                'doctor' : doctor,
                num_recibe_type,
                receipt,
                header,
                active
            };
        });
        
        switch (params.typeNotFilter) {
            case 'carga inicial':
                commit('setMovementsInitCharge', movements );
            break;
            case 'ingreso':
                commit('setMovementsIncome', movements )
            break;
            case 'egreso':
                commit('setMovementsEgress', movements );
            break;
            case 'all':
                commit('setMovements', movements );
            break;
        }

        commit('setLoading', false);
        return {
            ok: true,
            total : data.meta.total ?? 10,
            data : movements
        }
    } catch (error) {
        commit('setLoading', false);
        code = (error.response && error.response.status) ? error.response.status : 500;

        if (error.response && error.response.data && error.response.data.message && error.response.data.message.includes('401 Unauthorized')){
            code = 401;
        }

        return {
            ok: false,
            code: code,
            message: 'No se pudieron obtener los movimientos.'
        }
    }
}

/**
 *  Crea movimiento
 */
export const createMovement = async ({ commit, rootState }, dataCreate) => {
    try {
        commit('setLoading', true);
        const { data } = await routingRuleApi.post(`/api/v1/movements`, dataCreate, rootState.auth.config);

        commit('setLoading', false);
        return {
            ok: true,
            message:" Se creo correctamente ",
            data
        }
    } catch (error) {
        let errors = [];
        let message = 'Hubo un problema en la creación, reintente luego...';
        if(error.response.data.errors){
            errors = error.response.data.errors;
        }
        
        if(error.response.data.message){
            message = error.response.data.message;
        }

        commit('setLoading', false);

        return {
            ok: false,
            message,
            errors
        }
    }
}

export const deleteMovement = async ({ commit, rootState }, id) => {
    try {
        const { data } = await routingRuleApi.delete(`/api/v1/movements/${id}`,  rootState.auth.config);

        return {
            ok: true,
            message:" Se elimino correctamente ",
            data
        }
    } catch (error) {
        let errors = [];
        let message = 'Hubo un problema en la creación, reintente luego...';
        if(error.response.data.errors){
            errors = error.response.data.errors;
        }
        
        if(error.response.data.message){
            message = error.response.data.message;
        }

        return {
            ok: false,
            message,
            errors
        }
    }
}





/**
 *  Obtener productos
 */
export const getProduct = async ({ commit , rootState },query) => {
    let code = 200;
    try {
        
        if(query.lenght == 0){
            return false;
        }

        const { data } = await routingRuleApi.get(`/api/v1/getProductByName?name=${query}`,rootState.auth.config);

        return {
            ok: true,
            data:data.products
        }

    } catch (error) {

        return {
            ok: false,
            code: code,
            message: 'No se pudieron obtener productos.'
        }
    }
}



/**
 *  Obtener productos
 */
export const getProductDetail = async ({ commit , rootState },params) => {
    let code = 200;

    const { pharmacy, product } = params;

    try {

        const { data } = await routingRuleApi.get(`/api/v1/products/${product}?movement=true&pharmacy=${pharmacy}`,rootState.auth.config);
        
        return {
            ok: true,
            data:data.product
        }

    } catch (error) {

        return {
            ok: false,
            code: code,
            message: 'No se pudieron obtener productos.'
        }
    }
}

/**
 *  Obtener Medicos
 */
export const getDoctor = async ({ commit , rootState },query) => {
    let code = 200;
    try {
        
        if(query.lenght == 0){
            return false;
        }

        const { data } = await routingRuleApi.get(`/api/v1/doctors?name=${query}`,rootState.auth.config);

        return {
            ok: true,
            data:data.data
        }

    } catch (error) {

        return {
            ok: false,
            code: code,
            message: 'No se pudieron obtener productos.'
        }
    }
}

/**
 *  Crear Medico
 */
export const createDoctor = async ({ commit , rootState },dataForm) => {
    let code = 200;
    try {
        
        const { data } = await routingRuleApi.post(`/api/v1/doctors`, dataForm, rootState.auth.config);

        return {
            ok: true,
            data:data.data
        }

    } catch (error) {

        return {
            ok: false,
            code: code,
            message: 'No se pudieron obtener productos.'
        }
    }
}


/**
 *  Obtener los Header Movements 
 */
export const getHeaderMovements = async ({ commit , rootState },params) => {
    let code = 200;
    let types = {
        'receta_vale': 'Receta/Vale',
        'recibo_oficial': 'Recibo Oficial'
    };
    try {
        const queryString = Object.entries(params)
                        .filter(([key, value]) => value !== '')
                        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
                        .join('&');
        
        const { data } = await routingRuleApi.get(`/api/v1/headerMovements?${queryString}`,rootState.auth.config);
        
        const headerMovements = data.data.map( headerMovements => {
            const { id, type_receipt, number_receipt, pharmacy } = headerMovements;
            
            return {
                id,
                type_receipt,
                number_receipt,
                pharmacy
            };
        });
        
        commit('setHeaderMovements', headerMovements );
        
        return {
            ok: true,
            data : headerMovements
        }
    } catch (error) {
        commit('setLoading', false);
        code = (error.response && error.response.status) ? error.response.status : 500;

        if (error.response && error.response.data && error.response.data.message && error.response.data.message.includes('401 Unauthorized')){
            code = 401;
        }

        return {
            ok: false,
            code: code,
            message: 'No se pudieron obtener los movimientos.'
        }
    }
}