export const setLoading = ( state, value ) => {
    state.isLoading = value;
}

export const setMovements = (state, value) => {
    state.movements = value;
}

export const setMovementsInitCharge = (state, value) => {
    state.movementsInitCharge = value;
}

export const setMovementsEgress = (state, value) => {
    state.movementsEgress = value;
}

export const setMovementsIncome = (state, value) => {
    state.movementsIncome = value;
}

export const setHeaderMovements = (state, value) => {
    state.headerMovements = value;
}

/**
 * Function in charge of setting an initial state and resetting the permanent cache (Only executed in LoginView).
 * 
 * @param {*} state 
 */

export const reset = (state) => {
    state.isLoading = false
    state.movements = []
    state.movementsInitCharge = []
    state.movementsEgress = []
    state.movementsIncome = []
    state.tableColumns = [
        {
            label: 'Producto',
            field: 'product',
            sortable: false,
            hidden: false
        },
        {
            label: 'Movimiento',
            field: 'type',
            sortable: false,
            hidden: false
        },
        {
            label: 'Fecha',
            field: 'date',
            sortable: false,
            hidden: false
        },
        {
            label: 'Cantidad',
            field: 'quantity',
            sortable: false,
            hidden: false
        },
        {
            label: 'Stock Actual',
            field: 'current_stock',
            sortable: false,
            hidden: true
        },
        {
            label: 'Procedencia',
            field: 'origin',
            sortable: false,
            hidden: true
        },
        {
            label: 'Fecha de comprobante',
            field: 'receipt.date_comp',
            sortable: false,
            hidden: true
        },
        {
            label: 'Tipo de recibo',
            field: 'recibe_type',
            sortable: false,
            hidden: true
        },
        {
            label: 'Numero de recibo',
            field: 'header.number_receipt',
            sortable: false,
            hidden: true
        },
        {
            label: 'Médico',
            field: 'doctor',
            sortable: false,
            hidden: true
        },
        {
            label: 'Acciones',
            field: 'buttons',
            sortable: false,
            hidden: false
        }
    ]
    state.TableColumnsNotAll = [
        {
            label: 'Producto',
            field: 'product',
            sortable: false,
            hidden: false
        },
        {
            label: 'Movimiento',
            field: 'type',
            sortable: false,
            hidden: false
        },
        {
            label: 'Fecha',
            field: 'date',
            sortable: false,
            hidden: false
        },
        {
            label: 'Cantidad',
            field: 'quantity',
            sortable: false,
            hidden: false
        },
        {
            label: 'UM',
            field: 'um',
            sortable: false,
            hidden: true
        },
        {
            label: 'Procedencia',
            field: 'origin',
            sortable: false,
            hidden: true
        },
        {
            label: 'Fecha de comprobante',
            field: 'receipt.date_comp',
            sortable: false,
            hidden: true
        },
        {
            label: 'Tipo de recibo',
            field: 'recibe_type',
            sortable: false,
            hidden: true
        },
        {
            label: 'Numero de recibo',
            field: 'header.number_receipt',
            sortable: false,
            hidden: true
        },
        {
            label: 'Médico',
            field: 'doctor',
            sortable: false,
            hidden: true
        },
        {
            label: 'Acciones',
            field: 'buttons',
            sortable: false,
            hidden: false
        }
    ]
}
