<template>
    <div class="form-check">
        <input
            class="form-check-input"
            :class="totalyClass"
            type="checkbox"
            :id="id"
            :name="name"
            :disabled="disabled"
            :checked="checked"
            v-model="isChecked"
            @input="onUpdateModelValue"
            :data-automation="`checkbox-${ id }`"
        >

        <label class="form-check-label" :for="id">
            {{ titleElement }}
            <slot></slot>
        </label>
        <br>
        <span v-if="textBelow" :class="classBelow">{{ textBelow }}</span>
    </div>
</template>

<script>
    import { ref } from 'vue';
    import { formProps } from '@/mixins';
    import { useVFInput } from '@/composables';

    export default {
        name: "VF-checkbox",
        props: {
            checked: {
                type: Boolean,
                default: false
            }
        },
        mixins: [
            formProps
        ],
        setup(props, { emit }) {
            const isChecked = ref(props.checked);
            const {
                totalyClass
            } = useVFInput(props, emit);

            const onUpdateModelValue = (event) => {
                emit('update:modelValue', event.target.checked);
            };

            return {
                totalyClass,
                isChecked,
                onUpdateModelValue
            };
        }
    };
</script>