import { routingRuleApi } from "@/api";

/**
 *  
 */
export const login = async ( { commit }, dataForm) => {
    try {

        commit('setLoading', true);
        let result = {
            ok:false,
            message:'El usuario o la contraseña son incorrectos '
        }
        
        const { data } = await routingRuleApi.post('api/v1/login', dataForm);
        
        if (data.ok) {
            result.ok = true;
            result.message = " Usuario Correcto ";
            result.data = data;
            commit('setToken', data.token);
            commit('setUser', data.data);
        }
        commit('setLoading', false);
        return result;
    } catch (error) {
        let message = 'El usuario o la contraseña son incorrectos ';
        commit('setLoading', false);
        return {
            ok: false,
            message
        }
    }
    
}

export const register = async ( { commit, rootState }, dataForm) => {
    try {
        commit('setLoading', true);
        let result = {
            ok:false,
            message:'Los datos son incorrectos '
        }
        const { data } = await routingRuleApi.post('/api/v1/register', dataForm);
        

        if (data.ok && data.user) {
            result.ok = true;
            result.message = " Creado correctamente ";
            commit('setUser', data.user);
        }
        commit('setLoading', false);
        return result;
    } catch (error) {
        let message = 'Los datos son incorrectos ';
        let errors = [];

        if(error.response && error.response.data && error.response.data.errors){
            errors = error.response.data.errors;
        }
        commit('setLoading', false);
        return {
            ok: false,
            message,
            errors
        }
    }
}

export const verificationCode = async ( { commit }, code) => {
    try {
        commit('setLoading', true);
        let result = {
            ok:false,
            message:'Codigo Incorrecto '
        }
        const { data } = await routingRuleApi.get(`/api/v1/verify/${code}`);
        
        
        if (data.ok && data.user) {
            result.ok = true;
            result.message = " Verificado correctamente ";
        }
        commit('setLoading', false);
        return result;
    } catch (error) {
        let message = 'Codigo Incorrecto ';
        let errors = [];

        if(error.response && error.response.data && error.response.data.errors){
            errors = error.response.data.errors;
        }
        commit('setLoading', false);
        return {
            ok: false,
            message,
            errors
        }
    }
}

export const resendCode = async ( { commit }, email) => {
    try {
        commit('setLoading', true);
        let result = {
            ok:false,
            message:'Codigo Incorrecto '
        }
        const { data } = await routingRuleApi.post(`/api/v1/verify/resend`,{"email":email});
        
        
        if (data.ok) {
            result.ok = true;
            result.message = ' Se envio el codigo '
        }
        commit('setLoading', false);
        return result;
    } catch (error) {
        let message = 'No se pudo enviar el codigo ';
        let errors = [];
        return {
            ok: false,
            message,
            errors
        }
    }
}

export const sendRecoveryCode = async ( { commit }, email) => {
    try {
        commit('setLoading', true);
        let result = {
            ok:false,
            message:'Email Incorrecto '
        }
        const { data } = await routingRuleApi.post(`/api/v1/recovery/send`,{"email":email});
        
        
        if (data.ok) {
            result.ok = true;
            result.message = ' Se envio el codigo '
        }
        commit('setLoading', false);
        return result;
    } catch (error) {
        commit('setLoading', false);
        let message = 'No se pudo enviar el codigo ';
        
        if (error.response && error.response.data && error.response.data.message) {
            message = error.response.data.message;
        }

        let errors = [];
        return {
            ok: false,
            message,
            errors
        }
    }
}


export const verificationPasswordCode = async ( { commit }, code) => {
    try {
        commit('setLoading', true);
        let result = {
            ok:false,
            message:'Código incorrecto '
        }
        const { data } = await routingRuleApi.post(`/api/v1/recovery/check`,{"code":code});
        
        
        if (data.ok) {
            result.ok = true;
            result.message = ' Codigo Correcto '
            commit('setUser', data.user);
        }
        
        commit('setLoading', false);
        return result;
    } catch (error) {
        commit('setLoading', false);
        let message = 'No se pudo enviar el codigo ';
        
        if (error.response && error.response.data && error.response.data.message) {
            message = error.response.data.message;
        }

        let errors = [];
        return {
            ok: false,
            message,
            errors
        }
    }
}

export const recoveryPassword = async ( { commit }, dataForm) => {
    try {
        commit('setLoading', true);
        let result = {
            ok:false,
            message:'Email Incorrecto '
        }
        const { data } = await routingRuleApi.post(`/api/v1/recovery/updatePassword`,dataForm);
        
        
        if (data.ok) {
            result.ok = true;
            result.message = ' Se restablecio el password correctamente '
        }
        commit('setLoading', false);
        return result;
    } catch (error) {
        commit('setLoading', false);
        let message = 'No se pudo reestablecer el password ';
        let errors = [];
        return {
            ok: false,
            message,
            errors
        }
    }
}


export const getVersion = async ( { commit }) => {
    try {
        const { data } = await routingRuleApi.get(`/api/v1/version`);
        
        commit('setVersion', data);
        
        return data;

    } catch (error) {
        console.error(error);
    }
}


export const updateUser = async ( { commit, rootState }, dataForm) => {
    try {
        commit('setLoading', true);
        let result = {
            ok:false,
            message:'Los datos son incorrectos '
        }
        
        const { data } = await routingRuleApi.put('/api/v1/login/'+dataForm.id, dataForm);
        

        if (data.ok) {
            result.ok = true;
            result.message = " Usuario Correcto ";
            result.data = data;
            commit('setToken', data.token);
            commit('setUser', data.data);
        }

        commit('setLoading', false);
        return result;
    } catch (error) {
        let message = 'Los datos son incorrectos ';
        let errors = [];

        if(error.response && error.response.data && error.response.data.errors){
            errors = error.response.data.errors;
        }
        commit('setLoading', false);
        return {
            ok: false,
            message,
            errors
        }
    }
}

