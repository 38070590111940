export const getTableColumns = ( state ) => {
    return state.tableColumns;
};

export const getTableColumnsNotAll = ( state ) => {
    return state.TableColumnsNotAll;
};

export const getIsLoading = ( state ) => {
    return state.isLoading;
}

export const getMovements = ( state ) => {
    return state.movements;
}

export const getMovementsInitCharge = ( state ) => {
    return state.movementsInitCharge;
}

export const getMovementsEgress = ( state ) => {
    return state.movementsEgress;
}

export const getMovementsIncome = ( state ) => {
    return state.movementsIncome;
}

export const getHeaderMovements = ( state ) => {
    return state.headerMovements;
}

export const getTableColumnsDetails = ( state ) => {
    return state.tableColumnsDetails;
}
