const PharmaciesList = () => import(/* webpackChunkName: "PharmaciesList" */ '@/modules/pharmacies/views/list.vue');
const PharmaciesCreate = () => import(/* webpackChunkName: "PharmaciesList" */ '@/modules/pharmacies/views/create.vue');
const PharmacyView = () => import(/* webpackChunkName: "PharmaciesList" */ '@/modules/pharmacies/views/view.vue');
const PharmacyEdit = () => import(/* webpackChunkName: "PharmaciesList" */ '@/modules/pharmacies/views/update.vue');


export default [
    {
        path: '/pharmacies',
        name: "Farmacias",
        component: PharmaciesList,
        meta : {
            in_menu : true,
            public:false,
            icon : 'fas fa-clinic-medical',
            requiresAuth:true,
            Miniumprofile:'users'
        }
    },
    {
        path: '/pharmacies/create',
        name: "crear-farmacia",
        component: PharmaciesCreate,
        meta : {
            in_menu : false,
            public:false,
            requiresAuth:true,
            Miniumprofile:'users'
        }
    },
    {
        path: '/pharmacies/view/:id',
        name: "ver-farmacia",
        component: PharmacyView,
        meta : {
            in_menu : false,
            public:false,
            requiresAuth:true,
            Miniumprofile:'users'
        }
    },
    {
        path: '/pharmacies/edit/:id',
        name: "editar-farmacia",
        component: PharmacyEdit,
        meta : {
            in_menu : false,
            public:false,
            requiresAuth:true,
            Miniumprofile:'users'
        }
    }
]

