export const setPharmacies = ( state, pharmacies ) => {
    state.pharmacies = pharmacies;
}

export const setLoading = ( state, value ) => {
    state.isLoading = value;
}

export const setPharmacy = ( state, pharmacie ) => {
    state.pharmacie = pharmacie;
}

/**
 * Function in charge of setting an initial state and resetting the permanent cache (Only executed in LoginView).
 * 
 * @param {*} state 
 */

export const reset = (state) => {
    state.isLoading = false
    state.pharmacies = []
    state.pharmacie = []
}