<template>
    <div class="l-navbar show-menu" id="nav-bar" >
        <nav class="nav">
            <div>
                <a href="#" class="nav_logo">
                    <img class="m-2" id="image_logo" src="@/assets/images/icon-verifarma-1.png"
                        style="justify-items: center; grid-template-columns: auto;" height="40"
                        alt="Logo Verifarma">

                    <img class="d-none" id="image_logo_reduced" src="@/assets/images/icon-verifarma-reduced.png"
                        style="justify-items: center; grid-template-columns: auto;" height="50"
                        alt="Logo Verifarma">
                </a>

                <div class="nav_list">
                    <a class="" v-for="(route, index) in rutasList" :class="getClass(route.meta.in_menu)" :key="index">
                        <template v-if="userProfile === 'superadmin' || (userProfile === 'users' && route.meta.Miniumprofile === userProfile)">
                            <router-link class="nav_link" v-if="route.meta.in_menu" :to="route.path"
                                :data-automation="`link-route-${route.name}`">
                                <i v-if="route.meta.icon" :class="route.meta.icon" class='nav_icon' :title="route.name"></i>
                                <span class="nav_name">{{ route.name }}</span>
                            </router-link>
                        </template>
                    </a>
                </div>
            </div>
            <div class="fixed-bottom w-10">
                <router-link class="nav_link" to="/" data-automation="button-exit">
                    <i class="fa-solid fa-right-from-bracket nav_icon"></i>
                    <span class="nav_name">Salir</span>
                </router-link>
            </div>
        </nav>
    </div>
</template>

<script>
import { computed, ref, onMounted, onBeforeUnmount, inject, onUpdated } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from "vuex";

export default {
    name: 'vf-menu',
    setup() {
        const router = useRouter();
        const userProfile = ref('users')
        const store = useStore();
        const havePharmacyValidated = ref(false);
        const eventBus = inject('eventBus');


        const getRutasList = () => {
            return router.getRoutes().filter(route => {
                return route.name !== 'Actas' && route.name !== 'Movimientos';
            });
        };

        const getClass = (in_menu) => {
            return !in_menu ? 'd-none' : '';
        };

        const isAuthenticated = computed(() => user.value.profile !== null);

        const updateHavePharmacy = (value) => {
            let result = false;
            if(value === undefined || value.length === 0){
                havePharmacyValidated.value = result;
                return result;
            }

            for (const key in user.value.pharmaciesUser) {
                const element = user.value.pharmaciesUser[key];
                if(element && element.validated && user.value.cuit){
                    result = true
                }
            }
            havePharmacyValidated.value = result;
        };
        const user = computed(() => store.getters['auth/getUser']);
        const profile = computed(() => store.getters['auth/getProfile']);

        onMounted(() => {
            eventBus.on('updateHavePharmacy', updateHavePharmacy);
            if(user.value && user.value.pharmaciesUser && user.value.pharmaciesUser.length > 0){
                for (const key in user.value.pharmaciesUser) {
                   const element = user.value.pharmaciesUser[key];
                    if(element && element.validated){
                        havePharmacyValidated.value = true;
                    }
                }
            }
            userProfile.value = profile.value
        });

        onUpdated(() => {
            userProfile.value = profile.value
        })


        onBeforeUnmount(() => {
            eventBus.off('updateHavePharmacy', updateHavePharmacy);
        });

        return {
            rutasList: computed(() => {
                return havePharmacyValidated.value ? router.getRoutes() : getRutasList();
            }),
            getClass,
            isAuthenticated,
            havePharmacyValidated,
            userProfile
        };
    },
}
</script>