export const getTableColumns = ( state ) => {
    return state.tableColumns;
};

export const getUsers = ( state ) => {
    return state.users;
};

export const getIsLoading = ( state ) => {
    return state.isLoading;
}

export const getUser = ( state ) => {
    return state.user;
};

export const getUsersPharmacies = ( state ) => {
    return state.usersPharmacies;
};
