export const getToken = ( state ) => {
    if (!state.token) {
        return localStorage.getItem("FD-Token");
    }
    return state.token;
};

export const getHeaders = ( state ) => {
    return state.config;
};

export const getUser = ( state ) => {
    return state.user;
};

export const getProfile = ( state ) => {
    return state.profile;
};

export const getIsLoading = ( state ) => {
    return state.isLoading;
}

export const getVersion = ( state ) => {
    return state.version;
}