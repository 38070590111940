export default () => ({
    tableColumns: [
        {
            label: 'Nombre fantasía',
            field: 'name',
            hidden:false,
            type: 'string'
        },
        {
            label: 'Cuit',
            field: 'cuit',
            hidden:false,
            type: 'string'
        },
        {
            label: 'Razon Social',
            field: 'company_name',
            hidden:false,
            type: 'string'
        },
        {
            label: 'Acciones',
            field: 'buttons',
            hidden:false,
            sortable: false
        }
    ],
    pharmacies: [],
    isLoading: false,
    pharmacie: {},
})