const UsersList = () => import(/* webpackChunkName: "UsersList" */ '@/modules/users/views/list.vue');
const UsersUpdate = () => import(/* webpackChunkName: "UsersList" */ '@/modules/users/views/update.vue');
const UsersPerfil = () => import(/* webpackChunkName: "UsersList" */ '@/modules/users/views/updateProfile.vue');
const UsersView = () => import(/* webpackChunkName: "UsersList" */ '@/modules/users/views/view.vue');


export default [
    {
        path: '/users',
        name: "Usuarios",
        component: UsersList,
        meta : {
            in_menu : true,
            icon : 'fas fa-users',
            requiresAuth:true,
            public:false,
            title:' Usuarios ',
            Miniumprofile:'superadmin'
        }
    },
    {
        path: '/users/view/:id',
        name: "Ver-Usuario",
        component: UsersView,
        meta : {
            in_menu : false,
            requiresAuth:true,
            public:false,
            title:' Ver usuario ',
            Miniumprofile:'superadmin'
        },
        exact: true
    },
    {
        path: '/users/update/:id',
        name: "Editar-Usuario",
        component: UsersUpdate,
        meta : {
            in_menu : false,
            requiresAuth:true,
            public:false,
            title:' Editar usuario ',
            Miniumprofile:'superadmin'
        }
    },
    {
        path: '/users/my_perfil',
        name: "Mi-Perfil",
        component: UsersPerfil,
        meta : {
            in_menu : false,
            requiresAuth:true,
            public:false,
            title:' Mi Perfil ',
            Miniumprofile:'users'
        }
    }
]