export const setLoading = ( state, value ) => {
    state.isLoading = value;
}

export const setRecords = ( state, value ) => {
    state.records = value;
}

export const setPharmacySelected = ( state, value ) => {
    state.pharmacieSelected = value;
}

/**
 * Function in charge of setting an initial state and resetting the permanent cache (Only executed in LoginView).
 * 
 * @param {*} state 
 */

export const reset = (state) => {
    state.isLoading = false
    state.records = []
    state.record = []
    state.tableColumns = [
        {
            label: 'Producto',
            field: 'product',
            sortable: false
        },
        {
            label: 'Fecha desde',
            field: 'start_date',
            sortable: false
        },
        {
            label: 'Fecha Hasta',
            field: 'end_date',
            sortable: false
        },
        {
            label: 'Estado',
            field: 'status',
            sortable: false
        },
        {
            label: 'Descarga',
            field: 'buttons',
            sortable: false
        }
    ]
    state.pharmacieSelected = false
}
