export default () => ({
    tableColumns: [
        {
            label: 'Producto',
            field: 'product',
            sortable: false,
            hidden: false
        },
        {
            label: 'Movimiento',
            field: 'type',
            sortable: false,
            hidden: false
        },
        {
            label: 'Fecha',
            field: 'date',
            sortable: false,
            hidden: false
        },
        {
            label: 'Cantidad',
            field: 'quantity',
            sortable: false,
            hidden: false
        },
        {
            label: 'UM',
            field: 'um',
            sortable: false,
            hidden: true
        },
        {
            label: 'Stock Actual',
            field: 'current_stock',
            sortable: false,
            hidden: true
        },
        {
            label: 'Procedencia',
            field: 'origin',
            sortable: false,
            hidden: true
        },
        {
            label: 'Fecha de comprobante',
            field: 'receipt.date_comp',
            sortable: false,
            hidden: true
        },
        {
            label: 'Tipo de recibo',
            field: 'recibe_type',
            sortable: false,
            hidden: true
        },
        {
            label: 'Numero de recibo',
            field: 'header.number_receipt',
            sortable: false,
            hidden: true
        },
        {
            label: 'Médico',
            field: 'doctor',
            sortable: false,
            hidden: true
        },
        {
            label: 'Acciones',
            field: 'buttons',
            sortable: false,
            hidden: false
        }
    ],
    tableColumnsNotAll: [
        {
            label: 'Producto',
            field: 'product',
            sortable: false,
            hidden: false
        },
        {
            label: 'Movimiento',
            field: 'type',
            sortable: false,
            hidden: false
        },
        {
            label: 'Fecha',
            field: 'date',
            sortable: false,
            hidden: false
        },
        {
            label: 'Cantidad',
            field: 'quantity',
            sortable: false,
            hidden: false
        },
        {
            label: 'UM',
            field: 'um',
            sortable: false,
            hidden: true
        },
        {
            label: 'Procedencia',
            field: 'origin',
            sortable: false,
            hidden: true
        },
        {
            label: 'Fecha de comprobante',
            field: 'receipt.date_comp',
            sortable: false,
            hidden: true
        },
        {
            label: 'Tipo de recibo',
            field: 'recibe_type',
            sortable: false,
            hidden: true
        },
        {
            label: 'Numero de recibo',
            field: 'header.number_receipt',
            sortable: false,
            hidden: true
        },
        {
            label: 'Médico',
            field: 'doctor',
            sortable: false,
            hidden: true
        },
        {
            label: 'Acciones',
            field: 'buttons',
            sortable: false,
            hidden: false
        }
    ],
    tableColumnsDetails : [
        {
            label: 'Producto',
            field: 'product_name',
            sortable: false
        },
        {
            label: 'Presentación',
            field: 'presentation',
            sortable: false
        },
        {
            label: 'Laboratorio',
            field: 'laboratory',
            sortable: false
        },
        {
            label: 'Cantidad',
            field: 'quantity',
            sortable: false
        },
        {
            label: 'UM',
            field: 'um',
            sortable: false
        },
        {
            label: 'Eliminar',
            field: 'buttons',
            sortable: false
        }
    ],
    isLoading: false,
    movements : [],
    movementsInitCharge : [],
    movementsEgress : [],
    movementsIncome : [],
    headerMovements : []
})