export const getTableColumns = ( state ) => {
    return state.tableColumns;
};

export const getIsLoading = ( state ) => {
    return state.isLoading;
}

export const getRecords = ( state ) => {
    return state.records;
}

export const getPharmacySelected = ( state ) => {
    return state.pharmacieSelected;
}

