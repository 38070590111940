import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/css/app.css';

import Toast, { useToast } from 'vue-toastification';
import "vue-toastification/dist/index.css";

import VueGoodTablePlugin from 'vue-good-table-next';
import 'vue-good-table-next/dist/vue-good-table-next.css'

import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.css'

import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.css';

import cfg from "@/service/Configurations"
await cfg.initialize()

import mitt from 'mitt';


const app = createApp(App);
app.use(store)
    .use(router)
    .use(Toast, {
        transition: "Vue-Toastification__bounce",
        maxToasts: 20,
        newestOnTop: true
    }).use(VueGoodTablePlugin)

app.component('MultiSelect', Multiselect)

app.config.globalProperties.$toast = useToast();
app.config.globalProperties.$swal = Swal;

const eventBus = mitt();
app.provide('eventBus', eventBus);

app.mount('#app');

document.addEventListener("DOMContentLoaded", function () {
    /*===== LINK ACTIVE =====*/
    const linkColor = document.querySelectorAll('.nav_link');

    function colorLink() {
        if (linkColor) {
            linkColor.forEach(l => l.classList.remove('active'));
            this.classList.add('active');
        }
    }

    linkColor.forEach(l => l.addEventListener('click', colorLink));
});