export const setUsers = ( state, users ) => {
    state.users = users;
}

export const setLoading = ( state, value ) => {
    state.isLoading = value;
}

export const setUser = ( state, user ) => {
    state.user = user;
}

export const setUsersPharmacies = ( state, pharmacies ) => {
    state.usersPharmacies = pharmacies;
}

/**
 * Function in charge of setting an initial state and resetting the permanent cache (Only executed in LoginView).
 * 
 * @param {*} state 
 */

export const reset = (state) => {
    state.users = []
    state.usersPharmacies = []
    state.isLoading = false
    state.user = {}
}