export default () => ({
    tableColumns: [
        {
            label: 'Producto',
            field: 'product',
            sortable: false
        },
        {
            label: 'Fecha desde',
            field: 'start_date',
            sortable: false
        },
        {
            label: 'Fecha Hasta',
            field: 'end_date',
            sortable: false
        },
        {
            label: 'Estado',
            field: 'status',
            sortable: false
        },
        {
            label: 'Descarga',
            field: 'buttons',
            sortable: false
        }
    ],
    isLoading: false,
    records : [],
    record : [],
    pharmacieSelected : false,
})