const RecordList = () => import(/* webpackChunkName: "RecordList" */ '@/modules/records/views/list.vue');
const RecordCreate = () => import(/* webpackChunkName: "RecordList" */ '@/modules/records/views/create.vue');


export default [
    {
        path: '/records',
        name: "Actas",
        component: RecordList,
        meta : {
            in_menu : true,
            icon : 'fas fa-book-medical',
            requiresAuth:true,
            public:false,
            title:'Actas',
            Miniumprofile:'users'
        }
    },
    {
        path: '/records/create',
        name: "Crear Acta",
        component: RecordCreate,
        meta : {
            in_menu : false,
            icon : '',
            requiresAuth:true,
            public:false,
            title:'Crear acta',
            Miniumprofile:'users'
        }
    }
]