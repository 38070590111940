export const getTableColumns = ( state ) => {
    return state.tableColumns;
};

export const getIsLoading = ( state ) => {
    return state.isLoading;
}

export const getProducts = ( state ) => {
    return state.products;
}

export const getProduct = ( state ) => {
    return state.product;
}

