export const setLoading = ( state, value ) => {
    state.isLoading = value;
}

export const setProducts = ( state, products ) => {
    state.products = products;
}

export const setProduct = ( state, product ) => {
    state.product = product;
}

/**
 * Function in charge of setting an initial state and resetting the permanent cache (Only executed in LoginView).
 * 
 * @param {*} state 
 */
export const reset = (state) => {
    state.tableColumns = [
        {
            label: 'Nombre',
            field: 'name',
            sortable: false
        },
        {
            label: 'GTIN',
            field: 'gtin',
            sortable: false
        },
        {
            label: 'Tipo Medicamento',
            field: 'medicine_type',
            sortable: false
        },
        {
            label: 'Laboratorio',
            field: 'laboratory',
            sortable: false
        },
        {
            label: 'Acciones',
            field: 'buttons',
            sortable: false
        }
    ],
    state.isLoading = false,
    state.products = [],
    state.product = []
}

