<template>
    <div class="form-check" :class="classInline">
        <input
            class="form-check-input"
            type="radio"
            :class="onGetClasses"
            :value="valueInput"
            :name="name"
            :id="id"
            :disabled="disabled"
            :checked="checked"
            :data-automation="`radio-${ id }`"
        >

        <label class="form-check-label" :for="id">
            {{ titleElement }}
        </label>
    </div>
</template>

<script>
    import { computed } from 'vue';
    import { formProps } from '@/mixins';
    import { useVFInput } from '@/composables';

    export default {
        name: "VF-radio",
        props: {
            checked: Boolean,
            classInline : String,
            valueInput : String
        },
        mixins: [
            formProps
        ],
        setup(props, { emit }) {
            const {
                modelValue,
                onGetClasses
            } = useVFInput(props, emit);

            const selected = computed({
                get() {
                    return modelValue.value;
                },
                set(val) {
                    emit('update:modelValue', val);
                }
            });

            return {
                selected,
                onGetClasses
            };
        }
    };
</script>
