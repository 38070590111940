<template>
    <label v-if="titleElement" :class="titleClass" :for="id">
        {{ titleElement }}
        <b class="text-danger ms-1" v-if="isRequired">*</b>
    </label>

    <div :class="onHasIcon">
        <span v-if="iconDir === 'left'" class="input-group-text" :class="classIcon">
            <i :class="onGetIcon"></i>
        </span>

        <input
            type="password"
            class="form-control vf-input"
            :class="totalyClass"
            :name="name"
            :id="id"
            :placeholder="placeholder"
            :value="modelValue"
            :disabled="disabled"
            :ref="id"
            @input="onUpdateModelValue"
            @blur="onValidatePassword"
            :valid="valid"
            :data-automation="`input-${ id }`"
        />

        <span v-if="iconDir === 'right'" class="input-group-text" :class="classIcon">
            <i :class="onGetIcon"></i>
        </span>
    </div>

    <span v-if="!valid" :class="classBelow">{{ textBelow }}</span>
</template>

<script>
    import { onMounted, watch } from 'vue';
    import { formProps } from '@/mixins';
    import { useVFInput } from '@/composables';

    export default {
        name: 'vf-inputPassword',
        mixins: [
            formProps
        ],
        setup(props, { emit }) {
            const {
                modelValue,
                totalyClass,
                valid,
                onGetClasses,
                onGetIcon,
                onHasIcon,
                onUpdateModelValue
            } = useVFInput(props, emit);

            const onValidatePassword = ( event, minLength = 8 ) => {
                const passwordEntered = event.target.value;
                valid.value = ((passwordEntered.trim() !== '') && (passwordEntered.length >= minLength));
                if (!valid.value) {
                    emit('updateClassBelow', 'text-danger');
                    emit('updateTextBelow', 'Password inválida');
                }
            };

            watch(() => valid.value, (newValue) => {
                totalyClass.value = onGetClasses(newValue);
            });

            onMounted(()=>{
                totalyClass.value = onGetClasses(valid.value);
            });

            return {
                modelValue,
                totalyClass,
                valid,
                onGetIcon,
                onHasIcon,
                onUpdateModelValue,
                onValidatePassword
            };
        }
    };
</script>