import { ref, computed, watch, defineEmits} from 'vue';

export default function useVFInput(props, emit) {
    const size = ref(props.size);
    const valid = ref(props.valid);
    const vfClass = ref(props.vfClass);
    const icon = ref(props.icon);
    const modelValue = ref(props.modelValue);
    const totalyClass = ref(props.totalyClass);

    const onGetClasses = (refValid = false) => {
        let sizeValue = size.value ? `form-control-${size.value}` : '';
        let isValidValue = refValid ? '' : 'vf-error';
        return `${sizeValue} ${isValidValue} ${vfClass.value}`;
    };

    const onGetIcon = computed(() => icon.value || '');

    const onHasIcon = computed(() => (icon.value ? 'input-group' : ''));

    const onUpdateModelValue = (event) => {
        modelValue.value = event.target.value;
        emit('update:modelValue', modelValue.value);
    };

    watch(() => props.valid, (newValue) => {
        totalyClass.value = onGetClasses(newValue);
    });

    const emits = defineEmits(['change']);


    return {
        icon,
        modelValue,
        size,
        totalyClass,
        valid,
        vfClass,
        onGetClasses,
        onGetIcon,
        onHasIcon,
        onUpdateModelValue,
        ...emits
    };
}