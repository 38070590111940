export const getTableColumns = ( state ) => {
    return state.tableColumns;
};

export const getPharmacies = ( state ) => {
    return state.pharmacies;
};

export const getIsLoading = ( state ) => {
    return state.isLoading;
}

export const getPharmacy = ( state ) => {
    return state.pharmacie;
};
