import { routingRuleApi } from "@/api";

/**
 *  Obtener las conexiones
 */
export const getPharmacies = async ({ commit , rootState },params) => {
    let code = 200;
    try {
        commit('setLoading', true);
        const queryString = Object.entries(params)
                        .filter(([key, value]) => value !== '')
                        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
                        .join('&');
        
        const { data } = await routingRuleApi.get(`/api/v1/pharmacy?${queryString}`,rootState.auth.config);
        const pharmacies = data.data.map( pharmacies => {
            const { id, name, company_name, cuit, active } = pharmacies;
            return {id, name, company_name, cuit, active };
        });
        commit('setPharmacies', pharmacies );
        commit('setLoading', false);
        
        return {
            ok: true,
            total : data.meta.total ?? 10
        }
    } catch (error) {
        commit('setLoading', false);
        code = (error.response && error.response.status) ? error.response.status : 500;

        if (error.response && error.response.data && error.response.data.message && error.response.data.message.includes('401 Unauthorized')){
            code = 401;
        }

        return {
            ok: false,
            code: code,
            message: 'No se pudieron obtener las farmacias.'
        }
    }
}

/**
 *  Obtener una farmacia
 */
export const getPharmacy = async ({ commit , rootState }, id) => {
    try {
        commit('setLoading', true);

        const { data } = await routingRuleApi.get(`/api/v1/pharmacy/${id}`,rootState.auth.config);

        commit('setLoading', false);
        commit('setPharmacy', data.pharmacy );

        return {
            ok: true,
            pharmacy:data.pharmacy
        }
    } catch (error) {
        commit('setLoading', false);

        return {
            ok: false,
            message: `No se pudo obtener la farmacia ${id}`
        }
    }
}

/**
 *  Crea una Farmacia
 */
export const createPharmacy = async ({ commit, rootState }, dataCreate) => {
    try {
        const { data } = await routingRuleApi.post(`/api/v1/pharmacy`, dataCreate, rootState.auth.config);

        return {
            ok: true,
            message:" Se creo la farmacia correctamente ",
            data
        }
    } catch (error) {
        let errors = [];
        let message = 'No se pudo crear la farmacia, reintente luego...';
        if(error.response.data.errors){
            errors = error.response.data.errors;
        }
        return {
            ok: false,
            message,
            errors
        }
    }
}

/**
 *  Crea una Farmacia
 */
export const updatePharmacy = async ({ commit, rootState }, dataUpdate) => {
    try {
        dataUpdate = quitarVacios(dataUpdate);
        const { data } = await routingRuleApi.put(`/api/v1/pharmacy/${dataUpdate.id}`, dataUpdate, rootState.auth.config);

        return {
            ok: true,
            message:" Se actualizo la farmacia correctamente ",
            data
        }
    } catch (error) {
        let errors = [];
        let message = 'No se pudo actualizar la farmacia, reintente luego...';
        if (error.response.data.message) {
            message = error.response.data.message;
        }
        if(error.response && error.response.data && error.response.data.errors){
            errors = error.response.data.errors;
        }
        return {
            ok: false,
            message,
            errors
        }
    }
}

/**
 *  "Eliminar" una Farmacia
 */
export const deactiveOrActivePharmacy = async ({ commit, rootState }, dataForm) => {
    try {
        const {id, value} = dataForm;
        const { data } = await routingRuleApi.delete(`/api/v1/pharmacy/${id}?action=${value}`, rootState.auth.config);

        let message = " Se desactivo la farmacia correctamente "
        if(value == 'active'){
            message = 'Se activo la farmacia correctamente'
        }

        return {
            ok: true,
            message:message,
            data
        }
    } catch (error) {
        let errors = [];
        let message = 'No se pudo realizar la accion sobre la farmacia, reintente luego...';
        
        if(error.response && error.response.data && error.response.data.errors){
            errors = error.response.data.errors;
        }
        return {
            ok: false,
            message,
            errors
        }
    }
}


export const getProvince = async ({ rootState }, query) => {
    try {
        if(query.lenght == 0){
            return false;
        }
        const { data } = await routingRuleApi.get(`/api/v1/province?name=${query}`, rootState.auth.config);

        return {
            ok: true,
            data:data.data
        }
    } catch (error) {
        return {
            ok: false,
            message: `No se pudo obtener provincias`
        }
    }
}

export const getCity = async ({ rootState }, query) => {
    try {

        const queryString = Object.entries(query)
                        .filter(([key, value]) => value !== '')
                        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
                        .join('&');

        const { data } = await routingRuleApi.get(`/api/v1/city?${queryString}`, rootState.auth.config);

        return {
            ok: true,
            data:data.data
        }
    } catch (error) {

        return {
            ok: false,
            message: `No se pudo obtener ciudades`
        }
    }
}

function quitarVacios(objeto) {
    const resultado = {};
    for (const clave in objeto) {
      if (objeto.hasOwnProperty(clave)) {
        if (objeto[clave] !== "" && objeto[clave] !== null && objeto[clave] !== undefined) {
          resultado[clave] = objeto[clave];
        }
      }
    }
    return resultado;
  }
