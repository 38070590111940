import { routingRuleApi } from "@/api";


/**
 *  get a list of medicines 
 */
export const getProducts = async ({ commit , rootState },params) => {
    let code = 200;
    try {
        commit('setLoading', true);
        const queryString = Object.entries(params)
                        .filter(([key, value]) => value !== '')
                        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
                        .join('&');
        
        const { data } = await routingRuleApi.get(`/api/v1/products?${queryString}`,rootState.auth.config);
        const products = data.products.data.map( products => {
            const { id, comercial_name , presentation_name, gtin, laboratory, control, active } = products;
            let nameProduct = `${comercial_name} ${presentation_name}`
            let laboratoryName = `${laboratory.name}`
            let medicineType = `${control.name}`
            return {id, "name": nameProduct, gtin, "laboratory": laboratoryName, "medicine_type": medicineType , active };
        });
        
        commit('setProducts', products );
        commit('setLoading', false);
        
        return {
            ok: true,
            total : data.products.total ?? 10
        }
    } catch (error) {
        commit('setLoading', false);
        code = (error.response && error.response.status) ? error.response.status : 500;

        if (error.response && error.response.data && error.response.data.message && error.response.data.message.includes('401 Unauthorized')){
            code = 401;
        }

        return {
            ok: false,
            code: code,
            message: 'No se pudieron obtener las farmacias.'
        }
    }
}

/**
 *  Obtener una farmacia
 */
export const getProduct = async ({ commit , rootState }, id) => {
    try {
        commit('setLoading', true);

        const { data } = await routingRuleApi.get(`/api/v1/products/${id}`,rootState.auth.config);

        commit('setLoading', false);
        commit('setProduct', data.product);

        return {
            ok: true
        }
    } catch (error) {
        commit('setLoading', false);

        return {
            ok: false,
            message: `No se pudo obtener el producto ${id}`
        }
    }
}


/**
 *  get a list of medicines types
 */
export const getMedicineType = async ({ commit , rootState }) => {
    try {
        commit('setLoading', true);

        const { data } = await routingRuleApi.get(`/api/v1/controls`,rootState.auth.config);

        commit('setLoading', false);

        return {
            ok: true,
            controls:data.controls
        }
    } catch (error) {
        commit('setLoading', false);

        return {
            ok: false,
            message: `No se pudo obtener los tipos de medicamentos`
        }
    }
}