const MovementsList = () => import(/* webpackChunkName: "MovementsList" */ '@/modules/movements/views/list.vue');


export default [
    {
        path: '/movements',
        name: "Movimientos",
        component: MovementsList,
        meta : {
            in_menu : true,
            icon : 'fas fa-file-medical',
            requiresAuth:true,
            public:false,
            title:'Movimientos',
            Miniumprofile:'users'
        }
    }
]