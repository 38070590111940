export default () => ({
    tableColumns: [
        {
            label: 'Nombre',
            field: 'name',
            sortable: false
        },
        {
            label: 'Email',
            field: 'email',
            sortable: false
        },
        {
            label: 'CUIT',
            field: 'cuit',
            sortable: false
        },
        {
            label: '',
            field: 'buttons',
            sortable: false
        }
    ],
    users: [],
    usersPharmacies: [],
    isLoading: false,
    user: {},
})