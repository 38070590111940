export const setToken = ( state, token ) => {
    localStorage.setItem("FD-Token", token);
    state.token = token;
    setHeaders(state, token);
}

export const setHeaders = ( state, token ) => {
    state.config.headers.Authorization = "Bearer " + token;
}

export const setUser = ( state, user ) => {
    state.user = user;
    if(user.profile){
        setProfile(state, user.profile)
    }
}

export const setProfile = ( state, profile ) => {
    state.profile = profile;
}

export const addPharmacieUser = ( state, values ) => {
    if (!state.user.pharmaciesUser || !Array.isArray(state.user.pharmaciesUser)) {
        state.user.pharmaciesUser = [];
    }

    const index = state.user.pharmaciesUser.findIndex(pharmacy => pharmacy.value === values.value);
    if (index !== -1) {
        state.user.pharmaciesUser[index] = values;
    } else {
        state.user.pharmaciesUser.push(values);
    }
}

export const updatePharmacieUser = ( state, values ) => {
    state.user.pharmaciesUser.push(values);
}


export const setLoading = ( state, value ) => {
    state.isLoading = value;
}

export const setPharmacyOperation = ( state, value ) => {
    localStorage.setItem("FD-Pharma", value);
    state.pharmacyOperation = value;
}

export const setVersion = ( state, value ) => {
    state.version = value;
}

/**
 * Function in charge of setting an initial state and resetting the permanent cache (Only executed in LoginView).
 * 
 * @param {*} state 
 */
export const reset = (state) => {
    state.token = false
    state.user = false
    state.profile ='users'
    state.isLoading = false
    state.pharmacyOperation =false
    state.version = ''
    state.config = {
        headers: {
          'Authorization': '',
          "Access-Control-Allow-Origin": "*"
        }
    }
}
