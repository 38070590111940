import { routingRuleApi } from "@/api";

/**
 *  Obtener las actas
 */

export const getRecords = async ({ commit , rootState },params) => {
    let code = 200;
    try {
        commit('setLoading', true);
        const queryString = Object.entries(params)
                        .filter(([key, value]) => value !== '')
                        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
                        .join('&');
        
        const { data } = await routingRuleApi.get(`/api/v1/records?${queryString}`,rootState.auth.config);
        
        const records = data.data.map( records => {
            const { id, start_date, end_date, status,file } = records;
            let product = `${records.product.comercial_name} ${records.product.presentation_name }`
            return {
                id, 
                start_date,
                end_date,
                status,
                'product': product,
                file,
            };
        });
        
        commit('setRecords', records );

        commit('setLoading', false);
        
        return {
            ok: true,
            total : data.meta.total ?? 10
        }
    } catch (error) {
        commit('setLoading', false);
        code = (error.response && error.response.status) ? error.response.status : 500;

        if (error.response && error.response.data && error.response.data.message && error.response.data.message.includes('401 Unauthorized')){
            code = 401;
        }

        return {
            ok: false,
            code: code,
            message: 'No se pudieron obtener los movimientos.'
        }
    }
}

/**
 *  Crea Acta
 */
export const createRecord = async ({ commit, rootState }, dataCreate) => {
    try {
        commit('setLoading', true);
        let errors = [];
        let message = " Se creo correctamente ";
        const { data } = await routingRuleApi.post(`/api/v1/records`, dataCreate, rootState.auth.config);
        
        if(!data.ok && data.errors){
            errors = data.errors;
        }
        commit('setLoading', false);
        return {
            ok: data.ok,
            message,
            errors
        }
    } catch (error) {
        let errors = [];
        let message = 'Hubo un problema en la creación, reintente luego...';
        if(error.response.data.errors){
            errors = error.response.data.errors;
        }
        
        if(error.response.data.message){
            message = error.response.data.message;
        }

        commit('setLoading', false);

        return {
            ok: false,
            message,
            errors
        }
    }
}