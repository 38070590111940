import { createStore } from "vuex";
import auth from "@/modules/auth/store";
import users from "@/modules/users/store";
import pharmacies from "@/modules/pharmacies/store";
import products from "@/modules/products/store";
import movements from "@/modules/movements/store";
import records from "@/modules/records/store";
import createPersistedState from "vuex-persistedstate";

const store = createStore({
    modules: {
        auth,
        users,
        pharmacies,
        products,
        movements,
        records
    },
    plugins: [createPersistedState()]
});

export default store;