export default () => ({
    token: false,
    user: false,
    profile:'users',
    isLoading: false,
    version: false,
    config : {
        headers: {
          'Authorization': '',
          "Access-Control-Allow-Origin": "*"
        }
    }
});