const ProductList = () => import(/* webpackChunkName: "ProductList" */ '@/modules/products/views/list.vue');
const ProductView = () => import(/* webpackChunkName: "ProductList" */ '@/modules/products/views/view.vue');


export default [
    {
        path: '/products',
        name: "Productos",
        component: ProductList,
        meta : {
            in_menu : true,
            havePharmacy:true,
            icon : 'fas fa-tablets',
            requiresAuth:true,
            public:false,
            title:' Productos',
            Miniumprofile:'users'
        }
    },
    {
        path: '/products/view/:id',
        name: "Ver-Producto",
        component: ProductView,
        meta : {
            in_menu : false,
            requiresAuth:true,
            public:false,
            title:' Ver Producto ',
            Miniumprofile:'users'
        }
    }
]